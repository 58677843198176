.meter {
    box-sizing: content-box;
    height: 12px;
    position: relative;
    border-radius: 25px;
    background: #E5E5E3;
}
.meter > span {
    display: block;
    height: 100%;
    border-radius: 50px;
    background-color: #F29912;
    position: relative;
    overflow: hidden;
    transition: width 1s;
}
.meter > span:after,
.animate > span > span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,

            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,

            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,

            transparent
    );
    z-index: 1;
    background-size: 15px 15px;
    animation: move 3s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

.animate > span:after {
    display: none;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}